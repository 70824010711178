<template>
    <main>
        <header class="page-header page-header-compact page-header-light border-bottom bg-white mb-4">
            <div class="container-fluid">
                <div class="page-header-content">
                    <div class="row align-items-center justify-content-between pt-3">
                        <div class="col-auto mb-3">
                            <h1 class="page-header-title">
                                <div class="page-header-icon">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                        stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                        class="feather feather-user">
                                        <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                        <circle cx="12" cy="7" r="4"></circle>
                                    </svg>
                                </div>
                                Datos del usuario para editar
                            </h1>
                        </div>
                    </div>
                </div>
            </div>
        </header>
        <div class="modal-body">

            <form>
                <h5 class="card-title text-muted"><i class="fas fa-user-shield"></i>Datos personales</h5>
                <div class="form-row">
                    <div class="form-group col-md-6 input-group-sm">
                        <label class="small mb-1 text-muted " for="inputFirstName">Primer nombre</label>
                        <input class="form-control" font-size="responsive"
                            :class="{ 'is-invalid': $v.usuarios.nombre_1.$error }" v-model="usuarios.nombre_1" required>
                    </div>
                    <div class="form-group col-md-6 input-group-sm">
                        <label class="small mb-1 text-muted" for="inputLastName">Segundo nombre </label>
                        <input class="form-control" type="text" font-size="responsive"
                            v-model="usuarios.nombre_2">

                    </div>
                </div>
                <div class="form-row">
                    <div class="form-group col-md-6 input-group-sm">
                        <label class="small mb-1 text-muted" for="inputFirstName">Primer apellido</label>
                        <input class="form-control" id="inputFirstName" type="text" font-size="responsive"
                            :class="{ 'is-invalid': $v.usuarios.apellido_1.$error }" v-model="usuarios.apellido_1"
                            required>
                    </div>
                    <div class="form-group col-md-6 input-group-sm">
                        <label class="small mb-1 text-muted" for="inputLastName">Segundo apellido </label>
                        <input class="form-control" type="text" font-size="responsive"
                            v-model="usuarios.apellido_2">
                    </div>
                </div>
                <div class="form-row">
                    <div class="form-group col-md-3 input-group-sm">
                        <label class="small mb-1 text-muted" for="inputFirstName">Genero</label>
                        <div class="input-group mb-2 mr-2 input-group-joined  input-group-sm">
                            <div class="input-group-prepend">
                                <span class="input-group-text"> </span>
                            </div>
                            <select class="form-control" placeholder="" font-size="responsive"
                                :class="{ 'is-invalid': $v.usuarios.genero.$error }" v-model="usuarios.genero" required>
                                <option v-for="(genero, index) in generos" :key="index" :value="genero.nombre_guardado">
                                    {{ genero.nombre }}-{{genero.nombre_guardado }}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="form-group col-md-3 input-group-sm">
                        <label class="small mb-1 text-muted" for="inputLastName">Documento</label>
                        <input class="form-control" id="inputLastName" type="text" font-size="responsive"
                            :class="{ 'is-invalid': $v.usuarios.documento.$error }" v-model="usuarios.documento">
                    </div>
                    <div class="form-group col-md-3 input-group-sm">
                        <label class="small mb-1 text-muted">Tipo documento </label>
                        <select class="form-control form-control-sm" placeholder="" aria-label="tercero"
                            font-size="responsive" :class="{'is-invalid': $v.usuarios.id_tipo_documento.$error,}"
                            v-model="usuarios.id_tipo_documento">
                            <option v-for="(tipoDocumento, index) in tipoDocumentos" font-size="responsive"
                                v-bind:key="index" :value=tipoDocumento.id>
                                {{tipoDocumento.nombre}}
                            </option>
                        </select>
                    </div>
                    <div class="form-group col-md-3 input-group-sm">
                        <label class="small mb-1 text-muted" for="inputLastName">Fecha de nacimiento
                        </label>
                        <input class="form-control" type="date" font-size="responsive"
                            :class="{'is-invalid': $v.usuarios.fecha_nacimiento.$error, }"
                            v-model="usuarios.fecha_nacimiento" required>
                    </div>
                </div>

            </form>
            <h5 class="card-title text-muted"><i class="fas fa-check-double"></i>Informacion general</h5>

            <form>

                <div class="form-row">
                    <div class="form-group col-md-6  input-group-sm">
                        <label class="small mb-1 text-muted" for="inputFirstName">ocupacion</label>
                        <VSelectProfesion v-model="usuarios.id_profesion" mode="object">
                        </VSelectProfesion>
                    </div>
                    <div class="form-group col-md-6  input-group-sm">
                        <label class="small mb-1 text-muted" for="inputLastName">Estado civil </label>
                        <vSelectEstadoCivil v-model="usuarios.estado_civil " mode="object">
                        </vSelectEstadoCivil>
                    </div>
                </div>
                <div class="form-row">
                    <div class="form-group col-md-6  input-group-sm">
                        <label class="small mb-1 text-muted" for="inputLastName" teext_color="black">Etnia
                        </label>
                        <vSelectEtnia v-model="usuarios.etnia " mode="object">
                        </vSelectEtnia>
                    </div>
                    <div class="form-group col-md-6  input-group-sm">
                        <label class="small mb-1 text-muted" for="inputLastName">Telefono </label>
                        <input class="form-control" type="text" font-size="responsive"
                            :class="{ 'is-invalid': $v.usuarios.telefono.$error }" v-model="usuarios.telefono" required>
                    </div>

                </div>
                <div class="form-row">
                    <div class="form-group col-md-6  input-group-sm">
                        <label class="small mb-1 text-muted" for="inputLastName">Eps </label>
                        <v-select-eps mode="object" v-model="usuarios.eps"
                            :class="{ 'is-invalid': $v.usuarios.municipio.$error }">
                        </v-select-eps>
                    </div>
                    <div class="form-group col-md-6  input-group-sm">
                        <label class="small mb-1 text-muted" for="nivel">Nivel</label>
                        <select class="form-control" id="nivel" v-model="usuarios.nivel">
                            <option value="1" selected> Nivel 1</option>
                            <option value="2"> Nivel 2</option>
                            <option value="3"> Nivel 3</option>
                        </select>
                    </div>

                </div>
            </form>
            <h5 class="card-title text-muted"><i class="fas fa-street-view"></i>Ubicacion del usuario</h5>
            <div class="form-row">


                <div class="form-group col-md-12  input-group-sm">
                    <label class="small mb-1 text-muted" for="inputLastName">Municipio </label>
                    <vSelectMunicipio v-model="usuarios.municipio"
                        :class="{ 'is-invalid': $v.usuarios.municipio.$error }" mode="object" required>
                    </vSelectMunicipio>
                </div>

            </div>
            <div class="form-row">
                <div class="form-group col-md-6  input-group-sm">
                    <label class="small mb-1 text-muted" for="inputLastName" teext_color="black">Direccion
                    </label>
                    <input class="form-control " type="text" font-size="responsive"
                        :class="{ 'is-invalid': $v.usuarios.direccion.$error }" v-model="usuarios.direccion" required>
                </div>
                <div class="form-group col-md-6  input-group-sm">
                    <label class="small mb-1 text-muted" for="inputLastName" teext_color="black">Zona</label>

                    <vSelectZona v-model="usuarios.zona" mode="object"></vSelectZona>

                </div>

            </div>
            <form>
            </form>
        </div>
        <button class="btn btn-primary" type="button" @click="update()" data-dismiss="modal">Guardar</button>

    </main>
</template>
<script>
    import Axios from "axios";
    import { required } from "vuelidate/lib/validators";
    import Swal from "sweetalert2/src/sweetalert2.js";
    import VSelectProfesion from "../../components/common/VSelectProfesion";
    import vSelectZona from "../../components/common/vSelectZona";
    import vSelectEtnia from "../../components/common/vSelectEtnia";
    import vSelectEstadoCivil from "../../components/common/vSelectEstadoCivil";
    import vSelectMunicipio from "../../components/common/vSelectMunicipio";
    import VSelectEps from "../../components/common/VSelectEps";
    import {isEmpty} from 'lodash';
    export default {
        //  props: ['id'],
        components: {
            VSelectProfesion,
            vSelectZona,
            vSelectEtnia,
            vSelectEstadoCivil,
            VSelectEps,
            vSelectMunicipio,
        },

        async created() {

            try {
                this.listarTipoDocumento();
                this.listarGeneros();

                let id = this.$route.params?.id;

                this.LoaderSpinnerShow();
                let data = await this.ver(id);
                this.usuarios.id = data?.id;
                this.usuarios.nombre_1 = data?.nombre_1;
                this.usuarios.nombre_2 = data?.nombre_2;
                this.usuarios.apellido_1 = data?.apellido_1;
                this.usuarios.apellido_2 = data?.apellido_2;
                this.usuarios.documento = data?.documento;
                this.usuarios.fecha_nacimiento = data?.fecha_nacimiento;
                this.usuarios.id_tipo_documento = isEmpty( data.tipo_documento) ? {} : data.tipo_documento.id;
                this.usuarios.genero = data?.sexo;
                this.usuarios.direccion = data?.direccion;
                this.usuarios.telefono = data?.telefono;
                this.usuarios.nivel = data?.nivel;

                this.usuarios.id_profesion = isEmpty(data.ocupacion)  ? {}
                : {id: data.ocupacion.id,
                text: `${data.ocupacion.codigo} ${data.ocupacion.nombre}`
                };

                this.usuarios.municipio = isEmpty( data.municipio) ? {} :{
                    id: data.municipio.id,
                    text: `${data.municipio.codigo} ${data.municipio.nombre}`
                };
                this.usuarios.zona = isEmpty( data.zona) ? {} :{
                    id: data.zona.id,
                    text: `${data.zona.codigo} ${data.zona.descripcion}`
                };
                this.usuarios.eps = isEmpty( data.eps) ? {} : {
                    id: data.eps.id,
                    label: `${data.eps.IDEPS} ${data.eps.DESEPS}` };

                this.usuarios.etnia = isEmpty(data.etnia) ? {} :
                {
                    id: data.etnia.id,
                    text: `${data.etnia.id} ${data.etnia.nombre}`,};

                this.usuarios.estado_civil = isEmpty(data.estado_civil) ? {} :
                {
                    id: data.estado_civil.id,
                    text: `${data.estado_civil.id} ${data.estado_civil.descripcion}`,
                };


                this.current_document = this.usuarios.documento;

                this.LoaderSpinnerHide();

            } catch (e) {
                console.error(e);
                this.LoaderSpinnerHide();
            }
        },
        data() {
            return {

                tipoDocumentos: [],
                generos: [],
                current_document: '',
                usuarios: {
                    id: "",
                    nombre_1: "",
                    nombre_2: "",
                    apellido_1: "",
                    apellido_2: "",
                    genero: "",
                    documento: "",
                    id_tipo_documento: "",
                    fecha_nacimiento: "",
                    id_profesion: {},
                    estado_civil: "",
                    etnia: {},
                    zona: {},
                    direccion: "",
                    municipio: {},
                    telefono: "",
                    eps: {},
                    nivel: "",
                },
            };
        },

        filters: {
            upper: function (value) {
                return value.toUpperCase();
            },
        },
        validations: {
            usuarios: {
                nombre_1: { required },
                apellido_1: { required },
                genero: { required },
                documento: {
                    required,
                    async isUnique(value) {

                        if (value === "") {
                            return true;
                        }

                        const response = await Axios.get(
                            `/api/usuarios-evt/usuario-duplicado/${value}`
                        );

                        return Boolean(response.data);
                    },
                },
                id_tipo_documento: { required },
                fecha_nacimiento: { required },
                direccion: { required },
                municipio: { required },
                telefono: { required },
                eps: { required },
            },
        },
        methods: {
            limpiar() {
                this.usuarios.nombre_1 = "";
                this.usuarios.nombre_2 = "";
                this.usuarios.apellido_1 = "";
                this.usuarios.apellido_2 = "";
                this.usuarios.genero = "";
                this.usuarios.documento = "";
                this.usuarios.id_tipo_documento = "";
                this.usuarios.fecha_nacimiento = "";
                this.usuarios.estado_civil = "";
                this.usuarios.direccion = "";
                this.usuarios.nivel = "";
                this.usuarios.telefono = "";
                this.usuarios.id_profesion = {};
                this.usuarios.etnia = {};
                this.usuarios.zona = {};
                this.usuarios.municipio = {};
                this.usuarios.eps = {};
            },
            listarTipoDocumento() {

                var url = "/api/tipoDocumento/listar";
                return Axios.get(url).then((response) => {
                    this.tipoDocumentos = response.data;
                });
            },
            listarGeneros() {
                var url = "/api/genero/listar";
                return Axios.get(url).then((response) => {
                    this.generos = response.data;
                });
            },
            async update() {
                try {

                    this.$v.$touch();

                    if (this.$v.$invalid) {
                        return;
                    }

                    var url = "/api/usuarios-evt/update";
                    await Axios.put(url, this.usuarios);
                    this.limpiar();
                    Swal.fire({
                        title: "base de datos ",
                        text: " actualizado con exito",
                        icon: "success",
                        confirmButtonText: "Aceptar",
                    });

                } catch (e) {
                    console.error(e);
                    Swal.fire('Ocurrio un error al, por favor intente nuevamente', '', 'error');
                }
            },
            async ver(id) {
                try {
                    let url = "/api/usuarios-evt/ver/" + id;
                    let response = await Axios.get(url);
                    return response?.data;
                } catch (e) {
                    console.error(e);
                }
            },
        },
    };
</script>
